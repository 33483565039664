import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

interface ISkill {
  key: string;
  name: string;
  pos: string;
}

interface ILanguage {
  key: string;
  name: string;
  pos: string;
}

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  skills: Array<ISkill>;
  languages: Array<ILanguage>;

  constructor(private store: AngularFirestore) {
    this.skills = new Array<ISkill>();
    this.languages = new Array<ILanguage>();
  }

  ngOnInit(): void {
    this.store.firestore.collection('skills').get().then(q => {
      q.forEach(d => {
        const key = d.data().key;
        const name = d.data().name;
        const pos = d.data().pos + '%';
        this.skills.push({key, name, pos});
      });
      this.skills.sort((w1, w2) => w1.name.localeCompare(w2.name));
    });
    this.store.firestore.collection('languages').get().then(q => {
      q.forEach(d => {
        const key = d.data().key;
        const name = d.data().name;
        const pos = d.data().pos + '%';
        this.languages.push({key, name, pos});
      });
      this.languages.sort((w1, w2) => w1.name.localeCompare(w2.name));
    });
  }

}
