import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

interface IEducation {
  key: string;
  name: string;
  start: number;
  end: number;
  about: string;
  sort: number;
}

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
  educations: Array<IEducation>;

  constructor(private store: AngularFirestore) {
    this.educations = new Array<IEducation>();
  }

  ngOnInit(): void {
    this.store.firestore.collection('educations').get().then(q => {
      q.forEach(d => {
        const key = d.data().key;
        const name = d.data().name;
        const start = d.data().start;
        const end = d.data().end;
        const about = d.data().about;
        const sort = d.data().sort;
        this.educations.push({key, name, start, end, about, sort});
      });
      this.educations.sort((w1, w2) => w2.sort - w1.sort);
    });
  }

}
