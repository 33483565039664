<div class="w3-content w3-margin-top" style="max-width:1400px;">
  <div class="w3-row-padding">
    <div class="w3-third w3-animate-left">
      <app-about></app-about>
    </div>
    <div class="w3-twothird w3-animate-right">
      <app-work></app-work>
      <app-education></app-education>
    </div>
  </div>
</div>
<app-footer></app-footer>
