import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

interface IWork {
  key: string;
  name: string;
  state: string;
  start: Date;
  end: Date;
  about: string;
  sort: number;
}

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css']
})
export class WorkComponent implements OnInit {
  works: Array<IWork>;

  constructor(private store: AngularFirestore) {
    this.works = new Array<IWork>();
  }

  ngOnInit(): void {
    this.store.firestore.collection('works').get().then(q => {
      q.forEach(d => {
        const key = d.data().key;
        const name = d.data().name;
        const state = d.data().state;
        const start = d.data().start.toDate();
        const end = d.data().end ? d.data().end.toDate() : null;
        const about = d.data().about;
        const sort = d.data().sort;
        this.works.push({key, name, state, start, end, about, sort});
      });
      this.works.sort((w1, w2) => w2.sort - w1.sort);
    });
  }
}
