<div class="w3-container w3-card w3-dark-grey w3-margin-bottom">
  <h2 class="w3-text-white w3-padding-16">
    <i class="fa fa-briefcase fa-fw w3-margin-right w3-xxlarge w3-text-light-blue"></i>
    Doświadczenie zawodowe
  </h2>

  <div class="w3-container" *ngFor="let w of works; index as i; last as isLast">
    <h5 class="w3-text-sand"><b>{{w.state}} / {{w.name}}</b></h5>
    <h6 class="w3-text-light-blue"><i class="fa fa-calendar fa-fw w3-margin-right"></i>
      {{w.start | date:'YYYY-MM'}} <i class="fa fa-angle-double-right fa-fw"></i>  <span class="w3-tag w3-light-blue w3-round" *ngIf="!w.end">Obecnie</span>{{w.end | date:'YYYY-MM'}}
    </h6>
    <p>{{w.about}}</p>
    <hr *ngIf="!isLast">
  </div>
</div>
