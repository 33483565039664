import { Component } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'szymczak-home';
  abouts = this.store.collection('abouts');

  constructor(private store: AngularFirestore) {}
}
