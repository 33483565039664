<div class="w3-dark-grey w3-text-white w3-card-4">
  <div class="w3-display-container">
    <img src="../../assets/img/avatar.png" style="width:100%" alt="Avatar" class="img-avatar">
  </div>
  <div class="w3-container">
    <p><i class="fa fa-user fa-fw w3-margin-right w3-large w3-text-light-blue"></i>Łukasz Dawid Szymczak</p>
    <p><i class="fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-light-blue"></i>Projektant, Programista</p>
    <p><i class="fa fa-home fa-fw w3-margin-right w3-large w3-text-light-blue"></i>
      <a href="https://goo.gl/maps/FAFzfmtgqYHdnUkH7" target="_blank">14-100 Ostróda, Polska</a>
    </p>
    <p>
      <i class="fa fa-envelope fa-fw w3-margin-right w3-large w3-text-light-blue"></i>
      <a href="mailto:luke.szymczak@gmail.com?subject=Mail from szymczak.dev">luke.szymczak@gmail.com</a>
    </p>
    <p>
      <i class="fa fa-phone fa-fw w3-margin-right w3-large w3-text-light-blue"></i>
      <a href="tel:+48508420552">+48 508 420 552</a>
    </p>
    <hr>
    <p class="w3-large"><b><i class="fa fa-asterisk fa-fw w3-margin-right w3-text-light-blue"></i>Umiejętności</b></p>
    <div *ngFor="let s of skills; index as i; last as isLast">
      <p>{{s.name}}</p>
      <div class="w3-sand w3-round-xlarge w3-small">
        <div class="w3-container w3-center w3-round-xlarge w3-light-blue" style="height:16px" [ngStyle]="{width: s.pos}"></div>
      </div>
    </div>
    <br>
    <p class="w3-large"><b><i class="fa fa-asterisk fa-fw w3-margin-right w3-text-light-blue"></i>Języki</b></p>
    <div *ngFor="let l of languages; index as i; last as isLast">
      <p>{{l.name}}</p>
      <div class="w3-sand w3-round-xlarge w3-small">
        <div class="w3-container w3-center w3-round-xlarge w3-light-blue" style="height:16px" [ngStyle]="{width: l.pos}"></div>
      </div>
    </div>
    <br>
  </div>
</div>
