<div class="w3-container w3-card w3-dark-grey">
  <h2 class="w3-text-white w3-padding-16">
    <i class="fa fa-graduation-cap fa-fw w3-margin-right w3-xxlarge w3-text-light-blue"></i>
    Edukacja
  </h2>

  <div class="w3-container" *ngFor="let e of educations; index as i; last as isLast">
    <h5 class="w3-text-sand"><b>{{e.name}}</b></h5>
    <h6 class="w3-text-light-blue"><i class="fa fa-calendar fa-fw w3-margin-right"></i>
      {{e.start}} <i class="fa fa-angle-double-right fa-fw"></i> {{e.end}}
    </h6>
    <p>{{e.about}}</p>
    <hr *ngIf="!isLast">
  </div>
</div>
